import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-rodamientos-chumaceras',
  templateUrl: './rodamientos-chumaceras.component.html',
  styleUrls: ['./rodamientos-chumaceras.component.scss']
})
export class RodamientosChumacerasComponent implements OnInit {

  products: any[] = [];

  constructor( private product: ProductsService ) { }

  ngOnInit() {
    this.findProduct();
  }

  // Filtra por producto especifico
  findProduct() {
    this.products = this.product.obtenerProductos.find( data => data.name === 'Rodamienos y Chumaceras');
  }

}
