import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  mainBanner = [
    {
      name: 'Bandas Transportadoras',
      img: '/assets/img/hero/repinarca_repuestos_industriales_caracas_03.jpg'
    }
  ];

  cardsProducts = [
    {
      name: 'Abrazaderas Industriales',
      img: 'assets/img/thumbnail/0_abrazaderas_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Abrazaderas Industriales de baja, media y alta presion para una segura instalación',
      url: '/productos/abrazaderas'
    },
    {
      name: 'Adaptadores Industriales',
      img: 'assets/img/thumbnail/0_adaptadores_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Adaptadores metálicos para distintas conexiones o terminales en mangueras industriales.',
      url: '/productos/conexiones-mangueras'
    },
    {
      name: 'Bandas Transportadoras',
      img: 'assets/img/thumbnail/0_bandas_transportadoras_repinarca_caracas_venezuela.jpg',
      desc: 'Bandas transportadoras livianas, pesadas, alimenticia, para la industria arenera, grava, repuestos y lonas.',
      url: '/productos/bandas-livianas'
    },
    {
      name: 'Acoples Hidráulicos',
      img: 'assets/img/thumbnail/0_conexiones_mangueras_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Terminales metálicos que sirven como conectores hacia otros sistemas hidráulicos.',
      url: '/productos/acoples-hidraulicos'
    },
    {
      name: 'Correas Automotrices',
      img: 'assets/img/thumbnail/0_correas_automotrices_repinarca_caracas_venezuela.jpg',
      desc: 'Destinados a la movilización de motores automotrices tanto pequeños y de carga pesada.',
      url: '/productos/correas-automotrices'
    },
    {
      name: 'Correas Industriales',
      img: 'assets/img/thumbnail/0_correas_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Empleados en la movilización de motores de equipos industriales de pequeña o gran envergadura.',
      url: '/productos/correas-industriales'
    },
    {
      name: 'Estoperas',
      img: 'assets/img/thumbnail/0_estoperas_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Uniones de tuberías hidráulicas en materiales de gran calidad y diferentes presentaciones.',
      url: '/productos/empaquetadura-sellos'
    },
    {
      name: 'Frenos de Aire',
      img: 'assets/img/thumbnail/0_frenos_de_aire_repuestos_repinarca_caracas_venezuela.jpg',
      desc: 'Sistemas Neumáticos Automotrices y sus componentes como los Actuadores, Bandas, Válvulas y Racores.',
      url: '/productos/frenos-aire'
    },
    {
      name: 'Herramientas',
      img: 'assets/img/thumbnail/0_herramientas_dewalt_stanley_ampro_repinarca_caracas_venezuela.jpg',
      desc: 'Herramientas de reconocidas marcas como Ampro y Stanley de gran calidad para trabajos manuales.',
      url: '/productos/herramientas'
    },
    {
      name: 'Lubricantes',
      img: 'assets/img/thumbnail/0_lubricantes_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Lubricantes de alta calidad para sus equipos automotrices e industriales en diferentes presentaciones.',
      url: '/productos/lubricantes'
    },
    {
      name: 'Manguras Hidráulicas',
      img: 'assets/img/thumbnail/0_mangueras_industriales_hidraulias_repinarca_caracas_venezuela.jpg',
      desc: 'Cubren los requerimientos para el transporte de fluidos con rangos de presiones y temperaturas variables.',
      url: '/productos/mangueras-hidraulicas'
    },
    {
      name: 'Sistemas Neumáticos',
      img: 'assets/img/thumbnail/0_neumatica_industrial_repinarca_caracas_venezuela.jpg',
      desc: 'Amplia cantidad de conexiones, filtros y mangueras para sistemas neumáticos e instalaciones.',
      url: '/productos/neumaticos'
    },
    {
      name: 'O-Ring',
      img: 'assets/img/thumbnail/0_oring_repinarca_caracas_venezuela.jpg',
      desc: 'Disponible en tres Materiales, Buna (Nitrilo), Silicón y Vitón para una gran variedad de utilidad en la industria.',
      url: '/productos/oring'
    },
    {
      name: 'Pegamentos Industriales',
      img: 'assets/img/thumbnail/0_pegamentos_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Amplia gama de Pegamentos Industriales en sus distintas presentaciones y aplicaciones.',
      url: '/productos/pegamentos'
    },
    {
      name: 'Adaptadores Hidráulicos',
      img: 'assets/img/thumbnail/0_repuestos_adaptadores_hidraulicos_repinarca_caracas_venezuela.jpg',
      desc: 'Cubren una amplia gama de requerimiento en mangueras hidráulicas, bombas y otros elementos.',
      url: '/productos/adaptadores-hidraulicos'
    },
    {
      name: 'Conexiones de Bronce',
      img: 'assets/img/thumbnail/0_repuestos_conexiones_bronce_laton_repinarca_caracas_venezuela.jpg',
      desc: 'Abarcan una amplia gama de requerimiento para sistemas hidráulicos de baja y alta presión.',
      url: '/productos/conexiones-bronce'
    },
    {
      name: 'Rodamientos y Chumaceras',
      img: 'assets/img/thumbnail/0_rodamientos_industriales_repinarca_caracas_venezuela.jpg',
      desc: 'Rodamiento y soportes para los distintos tipos de configuraciones industriales.',
      url: '/productos/rodamientos-chumaceras'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
