import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactos',
  templateUrl: './contactos.component.html',
  styleUrls: ['./contactos.component.scss']
})
export class ContactosComponent implements OnInit {

  productBanner = 'assets/img/hero/repinarca_repuestos_industriales_caracas_22.jpg';
  lat = 10.348976;
  lng = -66.999300;
  constructor() { }

  ngOnInit() {
  }

}
