import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  products: any[] = [];

  constructor( private product: ProductsService ) {
    this.products = product.obtenerProductos;
  }

  ngOnInit() {
  }


}
