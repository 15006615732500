import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor( private meta: Meta, private title: Title ) {
    this.meta.addTags([
      {name: 'description', content: 'Respuestos Industriales, Automotriz, Agricultura, Minería y Maríautomotriztima'},
      {name: 'author', content: 'Repinarca CA'},
      {name: 'keywords', content: 'repuestos, industriales, abrazaderas, bandas, transportadoras, correas automotrices, correas industriales, conexiones mangueras, lubricantes, sistemas neumaticos, oring, rodamientos, chumaceras, frenos de aire'},
    ]);
    this.setTitle('Repinarca · Repuestos Industriales');
  }

  public setTitle( newTitle: string ) {
    this.title.setTitle( newTitle );
  }

}
