import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor() { }

  obtenerProductos: any[] = [
      {
        name: 'Abrazaderas Industriales',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_abrazaderas_industriales.jpg',
        router: 'abrazaderas'
      },
      {
        name: 'Bandas Transportadoras Livianas',
        urlImg: '../../../../../assets/img/products/1_repinarca_bandas_transportadoras_livianas_industriales.jpg',
        router: 'bandas-livianas'
      },
      {
        name: 'Bandas Transportadoras Pesadas',
        urlImg: '../../../../../assets/img/products/1_repinarca_bandas_transportadoras_pesadas_industriales.jpg',
        router: 'bandas-pesadas'
      },
      {
        name: 'Acoples Hidráulicos',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_acoples_hidraulicos.jpg',
        router: 'acoples-hidraulicos'
      },
      {
        name: 'Adaptadores Hidráulicos',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_adaptadores_hidraulicos.jpg',
        router: 'adaptadores-hidraulicos'
      },
      {
        name: 'Conexiones de bronce',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_conexiones_bronce_repuestos_industriales.jpg',
        router: 'conexiones-bronce'
      },
      {
        name: 'Conexiones Mangueras',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_conexiones_mangueras_hidraulicas_repuestos_industriales.jpg',
        router: 'conexiones-mangueras'
      },
      {
        name: 'Correas Automotrices',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_correas_automotrices_repuestos_industriales_1.jpg',
        router: 'correas-automotrices'
      },
      {
        name: 'Correas Industriales',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_correas_industriales_repuestos.jpg',
        router: 'correas-industriales'
      },
      {
        name: 'Empaquetaduras y Sellos',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_estoperas_industriales.jpg',
        router: 'empaquetadura-sellos'
      },
      {
        name: 'Frenos de Aire',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_frenos_de_aire_repuestos_industriales.jpg',
        router: 'frenos-aire'
      },
      {
        name: 'Herramientas',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_herramientas_repuestos_industriales.jpg',
        router: 'herramientas'
      },
      {
        name: 'Lubricantes',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_libricantes_repuestos_industriales.jpg',
        router: 'lubricantes'
      },
      {
        name: 'Mangueras Hidráulicas',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_mangueras_hidraulicas_repuestos_industriales.jpg',
        router: 'mangueras-hidraulicas'
      },
      {
        name: 'Sistemas Neumáticos',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_neumatica_repuestos_industriales.jpg',
        router: 'neumaticos'
      },
      {
        name: 'O-Ring',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_oring_repuestos_industriales.jpg',
        router: 'oring'
      },
      {
        name: 'Pegamentos',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_pegamentos_industriales.jpg',
        router: 'pegamentos'
      },
      {
        name: 'Rodamienos y Chumaceras',
        urlImg: '../../../../../assets/img/products/1_repinarca_portada_rodamientos_industriales.jpg',
        router: 'rodamientos-chumaceras'
      }
  ];

}
