import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Módulos
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';


// Componentes
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/navbar/pages/productos/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';

import { InicioComponent } from './components/navbar/pages/inicio/inicio.component';
import { EmpresaComponent } from './components/navbar/pages/empresa/empresa.component';
// tslint:disable-next-line: max-line-length
import { MangurasHidraulicasComponent } from './components/navbar/pages/productos/sidebar/manguras-hidraulicas/manguras-hidraulicas.component';
import { AbrazaderasComponent } from './components/navbar/pages/productos/sidebar/abrazaderas/abrazaderas.component';
import { ProductosComponent } from './components/navbar/pages/productos/productos.component';
import { ContactosComponent } from './components/navbar/pages/contactos/contactos.component';
import { BandasLivianasComponent } from './components/navbar/pages/productos/sidebar/bandas-livianas/bandas-livianas.component';
import { BandasPesadasComponent } from './components/navbar/pages/productos/sidebar/bandas-pesadas/bandas-pesadas.component';
import { AcoplesHidraulicosComponent } from './components/navbar/pages/productos/sidebar/acoples-hidraulicos/acoples-hidraulicos.component';
import { AdaptadoresHidraulicosComponent } from './components/navbar/pages/productos/sidebar/adaptadores-hidraulicos/adaptadores-hidraulicos.component';
import { ConexionesBronceComponent } from './components/navbar/pages/productos/sidebar/conexiones-bronce/conexiones-bronce.component';
import { ConexionesManguerasComponent } from './components/navbar/pages/productos/sidebar/conexiones-mangueras/conexiones-mangueras.component';
import { CorreasAutomotricesComponent } from './components/navbar/pages/productos/sidebar/correas-automotrices/correas-automotrices.component';
import { CorreasIndustrialesComponent } from './components/navbar/pages/productos/sidebar/correas-industriales/correas-industriales.component';
import { EmpaquetaduraSellosComponent } from './components/navbar/pages/productos/sidebar/empaquetadura-sellos/empaquetadura-sellos.component';
import { FrenosAireComponent } from './components/navbar/pages/productos/sidebar/frenos-aire/frenos-aire.component';
import { HerramientasComponent } from './components/navbar/pages/productos/sidebar/herramientas/herramientas.component';
import { LubricantesComponent } from './components/navbar/pages/productos/sidebar/lubricantes/lubricantes.component';
import { NeumaticosComponent } from './components/navbar/pages/productos/sidebar/neumaticos/neumaticos.component';
import { OringComponent } from './components/navbar/pages/productos/sidebar/oring/oring.component';
import { PagamentosComponent } from './components/navbar/pages/productos/sidebar/pagamentos/pagamentos.component';
import { RodamientosChumacerasComponent } from './components/navbar/pages/productos/sidebar/rodamientos-chumaceras/rodamientos-chumaceras.component';
import { SlideshowComponent } from './components/slideshow/slideshow.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    MangurasHidraulicasComponent,
    AbrazaderasComponent,
    InicioComponent,
    EmpresaComponent,
    ProductosComponent,
    ContactosComponent,
    BandasLivianasComponent,
    BandasPesadasComponent,
    AcoplesHidraulicosComponent,
    AdaptadoresHidraulicosComponent,
    ConexionesBronceComponent,
    ConexionesManguerasComponent,
    CorreasAutomotricesComponent,
    CorreasIndustrialesComponent,
    EmpaquetaduraSellosComponent,
    FrenosAireComponent,
    HerramientasComponent,
    LubricantesComponent,
    NeumaticosComponent,
    OringComponent,
    PagamentosComponent,
    RodamientosChumacerasComponent,
    SlideshowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB8Pt2N67tbWqeEikTYTiXVVovBbz1teSI'
    })
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
