import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit {

  ejemplo: string;
  slideshow: any[];

  constructor() {

   }

  ngOnInit() {

    this.slideshow = [
      {
        id: 1,
        name: 'producto-1',
        url: 'assets/img/hero/repinarca_repuestos_industriales_caracas_07.jpg'
      }
    ];

  }

}
