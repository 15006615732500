import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {

  productBanner = 'assets/img/hero/repinarca_repuestos_industriales_caracas_19.jpg';

  constructor() { }

  ngOnInit() {
  }

}
