// MODULOS
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// COMPONENTES
import { InicioComponent } from './components/navbar/pages/inicio/inicio.component';
import { EmpresaComponent } from './components/navbar/pages/empresa/empresa.component';
import { ContactosComponent } from './components/navbar/pages/contactos/contactos.component';
import { ProductosComponent } from './components/navbar/pages/productos/productos.component';

// PRODUCTOS
import { AbrazaderasComponent } from './components/navbar/pages/productos/sidebar/abrazaderas/abrazaderas.component';
import { AcoplesHidraulicosComponent } from './components/navbar/pages/productos/sidebar/acoples-hidraulicos/acoples-hidraulicos.component';
import { AdaptadoresHidraulicosComponent } from './components/navbar/pages/productos/sidebar/adaptadores-hidraulicos/adaptadores-hidraulicos.component';
import { BandasLivianasComponent } from './components/navbar/pages/productos/sidebar/bandas-livianas/bandas-livianas.component';
import { BandasPesadasComponent } from './components/navbar/pages/productos/sidebar/bandas-pesadas/bandas-pesadas.component';
import { ConexionesBronceComponent } from './components/navbar/pages/productos/sidebar/conexiones-bronce/conexiones-bronce.component';
import { ConexionesManguerasComponent } from './components/navbar/pages/productos/sidebar/conexiones-mangueras/conexiones-mangueras.component';
import { CorreasAutomotricesComponent } from './components/navbar/pages/productos/sidebar/correas-automotrices/correas-automotrices.component';
import { CorreasIndustrialesComponent } from './components/navbar/pages/productos/sidebar/correas-industriales/correas-industriales.component';
import { EmpaquetaduraSellosComponent } from './components/navbar/pages/productos/sidebar/empaquetadura-sellos/empaquetadura-sellos.component';
import { FrenosAireComponent } from './components/navbar/pages/productos/sidebar/frenos-aire/frenos-aire.component';
import { HerramientasComponent } from './components/navbar/pages/productos/sidebar/herramientas/herramientas.component';
import { LubricantesComponent } from './components/navbar/pages/productos/sidebar/lubricantes/lubricantes.component';
import { MangurasHidraulicasComponent } from './components/navbar/pages/productos/sidebar/manguras-hidraulicas/manguras-hidraulicas.component';
import { NeumaticosComponent } from './components/navbar/pages/productos/sidebar/neumaticos/neumaticos.component';
import { OringComponent } from './components/navbar/pages/productos/sidebar/oring/oring.component';
import { PagamentosComponent } from './components/navbar/pages/productos/sidebar/pagamentos/pagamentos.component';
import { RodamientosChumacerasComponent } from './components/navbar/pages/productos/sidebar/rodamientos-chumaceras/rodamientos-chumaceras.component';

const routes: Routes = [
  { path: 'inicio', component: InicioComponent },
  { path: 'la-empresa', component: EmpresaComponent },
  { path: 'productos',
    component: ProductosComponent,
    children: [
      { path: 'abrazaderas', component: AbrazaderasComponent },
      { path: 'acoples-hidraulicos', component: AcoplesHidraulicosComponent },
      { path: 'adaptadores-hidraulicos', component: AdaptadoresHidraulicosComponent },
      { path: 'bandas-livianas', component: BandasLivianasComponent },
      { path: 'bandas-pesadas', component: BandasPesadasComponent },
      { path: 'conexiones-bronce', component: ConexionesBronceComponent },
      { path: 'conexiones-mangueras', component: ConexionesManguerasComponent },
      { path: 'correas-automotrices', component: CorreasAutomotricesComponent },
      { path: 'correas-industriales', component: CorreasIndustrialesComponent },
      { path: 'empaquetadura-sellos', component: EmpaquetaduraSellosComponent },
      { path: 'frenos-aire', component: FrenosAireComponent },
      { path: 'herramientas', component: HerramientasComponent },
      { path: 'lubricantes', component: LubricantesComponent },
      { path: 'mangueras-hidraulicas', component: MangurasHidraulicasComponent },
      { path: 'neumaticos', component: NeumaticosComponent },
      { path: 'oring', component: OringComponent },
      { path: 'pegamentos', component: PagamentosComponent },
      { path: 'rodamientos-chumaceras', component: RodamientosChumacerasComponent },
    ]
   },
  { path: 'contactos', component: ContactosComponent },
  { path: '', pathMatch: 'full', redirectTo: 'inicio' },
  { path: '**', pathMatch: 'full', redirectTo: 'inicio' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
